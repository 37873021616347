import payload_plugin_dHWDzdkIDW from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_UjZwE1MBJg from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_q1HPXMn9dx from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vite@6.0.11_@types+n_wrzwmqclfhp3xu5slikamjdtma/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_xvgzfZ5Y6T from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1CCBoFimMA from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_cF74sd0a1d from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OO0JL3MZXj from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4LWBAVosIs from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_I0Awskc4lu from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_a9AKLlXUWT from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_nyoliz3ss5lh2qndqvme76pdme/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghV3gZerOZ from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7_h2hoydypdavl4ayuu6ritkabdi/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_Cd3n3Ja6aH from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vite@6.0.11_@types+n_wrzwmqclfhp3xu5slikamjdtma/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/app/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_UjZwE1MBJg,
  vuetify_icons_q1HPXMn9dx,
  unhead_xvgzfZ5Y6T,
  router_1CCBoFimMA,
  payload_client_cF74sd0a1d,
  navigation_repaint_client_OO0JL3MZXj,
  check_outdated_build_client_4LWBAVosIs,
  chunk_reload_client_I0Awskc4lu,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_a9AKLlXUWT,
  plugin_ghV3gZerOZ,
  vuetify_no_client_hints_Cd3n3Ja6aH,
  plugin_wy0B721ODc,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]